import { useState } from "react";
// import { AdvancedImage } from '@cloudinary/react';
// import { Cloudinary } from "@cloudinary/url-gen";
import Axios from "axios";

const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const Admin = () => {
  const [nextCursor, setNextCursor] = useState([]);
  const [searchParam, setSearchParam] = useState([]);
  const [cursorCount, setCursorCount] = useState(1);

  function apiTest() {
    Axios.get(`${api_address}/products/getCategories`).then((response) => {
      // console.log(response.data);
    });
  }

  function apiTest2() {
    Axios.get(`${api_address}/products/getItemList`).then((response) => {
      // console.log(response.data);
    });
  }

  function seriesByCat(category) {
    Axios.post(`${api_address}/products/seriesByCategory`, {
      category: category || "Stack Chairs",
    }).then((response) => {
      // console.log(response.data);
    });
  }
  function productBySeries(series) {
    Axios.post(`${api_address}/products/productBySeries`, {
      series: series || "Bernadine Vinyl Folding Chair",
    }).then((response) => {
      // console.log(response.data);
    });
  }

  function getCloudinaryAssets(cursor) {
    // console.log(nextCursor);
    Axios.post(`${api_address}/assets/getAssetData`, {
      nextCursor: nextCursor,
      cursorCount: cursorCount,
    }).then((response) => {
      setNextCursor(response.data.next_cursor);
      setCursorCount(cursorCount + 1);
      // console.log(response.data);
    });
  }

  function getCDNLinks() {
    Axios.get(`${api_address}/products/getImageLinks`).then((response) => {
      // console.log(response.data);
    });
  }

  function getProductTree() {
    Axios.get(`${api_address}/products/getProductTree`).then((response) => {
      // console.log(response.data);
    });
  }

  function updateCloudinaryImages() {
    Axios.get(`${api_address}/assets/updatecloudinaryimages`).then(
      (response) => {
        console.log(response.data);
      }
    );
  }

  return (
    <>
      <div>
        <button onClick={() => apiTest()}>Test API</button>
        <button onClick={() => apiTest2()}>Full Product API</button>
        <button onClick={() => seriesByCat()}>series by category</button>
        <button onClick={() => productBySeries()}>productBySeries</button>
        <button onClick={() => getCloudinaryAssets()}>
          Get Assets from Cloudinary
        </button>
        <button onClick={() => getCDNLinks()}>Get CDN Links</button>
        <button onClick={() => getProductTree()}>Get Full Tree</button>
        <button onClick={() => updateCloudinaryImages()}>
          update cloudinary table
        </button>
      </div>
    </>
  );
};

export default Admin;
